import Container from "../../Components/Atoms/layouts/container/Container";
import { useEffect, useState } from 'react';
import Api from '../../services/api/Api';
import { public_api } from "../../services/api/ApiList";
import BlogCard from "../../Components/Organisms/blog_card/BlogCard";
import InlineLoader from './../../Components/Atoms/loader/InlineLoader';

export default function BlogsHome() {

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Api.get(public_api.blogs).then(({ data }) => {
      if (data.success_bool) {
        setBlogs(data.result_data)
      }
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  return (
    <div className="other-page-container bg-color-brand-color4 color-111">
      <Container className="py-30 blog-container">
        {
          loading ? <InlineLoader text="loading blogs..." /> : ''
        }
        {
          blogs?.map(eachBlog => <BlogCard className="mb-40" key={eachBlog.blog_id} data={eachBlog} />)
        }
      </Container>
    </div>
  )
}