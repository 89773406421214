import { NavLink } from "react-router-dom";
import Button from "../../../Components/Atoms/form_elements/Button";
import Container from "../../../Components/Atoms/layouts/container/Container";

export default function Blogs() {
  return (
    <div className="other-page-container bg-color-brand-color4 color-111">
      <Container className="py-30">
        <div className="d-flex justify-content-space-between">
          <h2>Mange Blogs</h2>
          <NavLink to="/admin/blogs/post">
            <Button themeType="theme2">+ New Blog</Button>
          </NavLink>
        </div>
      </Container>
    </div>
  )
}