import { useRef, useState } from "react";
import Button from "../../../Components/Atoms/form_elements/Button";
import Container from "../../../Components/Atoms/layouts/container/Container";
import DisplayGrid from "../../../Components/Atoms/layouts/grid/DisplayGrid";
import DynamicSelect from "../../../Components/Atoms/dynamic_select/DynamicSelect";
import { admin_api } from "../../../services/api/ApiList";
import Api from "../../../services/api/Api"
import { toast } from 'react-toastify';
import toBase64 from "../../../services/utils/toBase64";
import InlineLoader from "../../../Components/Atoms/loader/InlineLoader";

export default function NewBlog() {

  const [posting, setPosting] = useState(false);
  const [mediaFiles, setMediaFiles] = useState();

  const { current: formData } = useRef({
    details: {},
    files: {}
  })

  function setDetailsPayload(key, value) {
    formData.details[key] = value
  }

  function SubmitFormTrigger(event) {

    event.preventDefault();


    const formPayload = new FormData();
    formPayload.append("details", JSON.stringify(formData.details));


    for(let i = 0; i < formData.files.length; i++) {
      formPayload.append("media_files", formData.files[i]);
    }

    setPosting(true);

    Api.post(admin_api.blogs.blogs_crud, formPayload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(({ data }) => {
      if (data.success_bool) {
        toast.success("Successfully posted a blog")
      }
    }).finally(() => {
      setPosting(false);
    })
  }

  function setFormFilePayload(event) {
    const files = event?.target?.files;
    console.log(files);
    // if (file && file.type.match(/application.*/)) {
    //   resumePayload.file = file;
    // }
    formData.files = files;

    const toBase64Promises = []
    for (let i = 0; i < files.length; i++) {
      toBase64Promises.push(toBase64(files[i]))
    }

    Promise.all(toBase64Promises).then((resArr) => {
      console.log(resArr);
      setMediaFiles(resArr)
    })
  }


  return (
    <div className="other-page-container bg-color-brand-color4 color-111">
      <Container className="py-30">
        <form onSubmit={SubmitFormTrigger}>
          <h2 className="mb-30">New Blog</h2>
          <DisplayGrid gap={20} col={2}>
            <div className="simple-form">

              <div className="form-group">
                <label>Title</label>
                <input required placeholder="Title of the blog" onChange={e => setDetailsPayload("title", e.target.value)} />
              </div>

              <div className="form-group">
                <label>Content</label>
                <textarea required className="w-100 p-10" style={{ minHeight: "200px" }} onChange={e => setDetailsPayload("text_content", e.target.value)} ></textarea>
              </div>

              <div className="form-group">
                <label>Author</label>
                <DynamicSelect required={true} apiUrl={admin_api.blogs.get_authors} labelKey="full_name" valueKey="id" onChange={e => setDetailsPayload("author_id", e.target.value)} />
              </div>
            </div>

            <div>
              <label>Add Images</label>
              <input type="file" multiple={true} accept="image/*" onChange={setFormFilePayload} />

              <DisplayGrid className="mt-20" gap={20} col={3}>
                {
                  mediaFiles?.map((eachMedia, i) => <img className="w-100" key={i} src={eachMedia} />)
                }
              </DisplayGrid>

            </div>
          </DisplayGrid>

          <div className="d-flex justify-content-flex-end mt-30">
            {
              posting? <InlineLoader text="Submiting..."/>: ''
            }

            <Button type="submit" themeType="theme2">Post Blog</Button>
          </div>
        </form>
      </Container>
    </div>
  )
}