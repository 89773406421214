import { useEffect, useState } from "react"
import Api from "../../../services/api/Api"

export default function DynamicSelect({ apiUrl, labelKey = "label", required = false, valueKey = "value", placeholder = "Select", defaultValue = "", onChange = () => { } }) {

  const [optionItems, setOptions] = useState([])

  useEffect(() => {
    Api.get(apiUrl).then(({ data }) => {
      if (data.success_bool) {
        setOptions(data.result_data)
      }
    })
  }, [])

  return (
    <select required={required} onChange={onChange} defaultValue={defaultValue}>
      <option value="" disabled>{placeholder}</option>
      {
        optionItems.map((eachOption, i) =>
          <option value={eachOption[valueKey]} key={i}>{eachOption[labelKey]}</option>
        )
      }
    </select>
  )
}