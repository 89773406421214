import style from "./BlogCard.module.scss"
import moment from 'moment';
import DisplayGrid from '../../Atoms/layouts/grid/DisplayGrid';

export default function BlogCard({ className = "", data }) {
  return (
    <div className={`${className} ${style.BlogCard}`}>
      <h4 className="font-size-18 title">{data.title}</h4>
      <p className="text-align-left">{data.author_info.author_full_name}</p>
      <p className="mb-10 ">{moment(data.created_at).fromNow()}</p>
      <p className="text_content">{data.text_content}</p>
      {
        data.media_content ?
          <DisplayGrid gap={20} col={2} colMobile={1} className="mt-20">
            {
              data.media_content?.map((eachMedia, i) => <img src={eachMedia.media_url} className="w-100" />)
            }
          </DisplayGrid> : ''
      }
    </div>
  )
}