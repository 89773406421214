
import { NavLink } from "react-router-dom"
import Container from "../../Atoms/layouts/container/Container"
import Logo from "../../Atoms/logo/logo"
import OurApps from "../our_apps/OurApps"
import style from "./TopNavBar.module.scss"
import { useState } from "react"

export default function TopNavBar() {

  const [mobileMenuActive, setMobileMenuActive] = useState(false);

  return (
    <>
      <div className={`${style.NavBar}`}>
        {/* <TopRibbon /> */}
        <Container className={`d-flex justify-content-space-between align-items-center flex-wrap-wrap py-10`}>
          <div className={`${style.LogoContainer}`}>
            <NavLink to="/">
              <Logo />
            </NavLink>
          </div>

          <div className="d-flex align-items-center">


            <div className={`${style.Navigations} ${mobileMenuActive ? style.active : ''}`}>

              <button className={style.MobileMenuClose} onClick={e => setMobileMenuActive(false)}>
                <ion-icon name="close-outline"></ion-icon>
              </button>

              <ul>
                <li>
                  <NavLink to="/" onClick={e => setMobileMenuActive(false)}>Home</NavLink>
                </li>
                <li>
                  <NavLink to="/services" onClick={e => setMobileMenuActive(false)}>Services</NavLink>
                </li>
                <li>
                  <NavLink to="/about-us" onClick={e => setMobileMenuActive(false)}>About Us</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/featured-works" onClick={e => setMobileMenuActive(false)}>Featured Works</NavLink>
                </li> */}
                <li>
                  <NavLink to="/blogs" onClick={e => setMobileMenuActive(false)}>Blogs</NavLink>
                </li>
                <li>
                  <NavLink to="/contact" onClick={e => setMobileMenuActive(false)}>Contact</NavLink>
                </li>
                <li>
                  <NavLink to="/careers" onClick={e => setMobileMenuActive(false)}>Careers</NavLink>
                </li>
              </ul>
            </div>

            <OurApps />


            <button className={style.MobileMenuTrigger} onClick={e => setMobileMenuActive(true)}>
              <ion-icon name="menu-outline"></ion-icon>
            </button>
          </div>

        </Container>
      </div>
    </>
  )
}